import { useGetCountries, useGetExchange } from 'features/utils/api'
import { useContext } from 'react'
import CommonContext from 'services/AuthContext'
import { reFormatNum } from 'shared/utils'

/**
 * Возвращает объект со св-ом isMobile в значении true, если размер окна меньше 768px (по умолчанию).
 * @param size принимает число пикселей, меньше которых будет считаться мобильным устройством.
 * */

function roundIfNotInteger(value: number, fixedNum: number) {
	const arr = new Array(fixedNum).fill(0).reduce((acc, val) => {
		return `${acc}${val}`
	}, '')

	const val = Number(1 + arr)

	if (value % 1 !== 0) {
		// Проверяем, что число не целое
		return new Intl.NumberFormat('ru-RU').format(Math.round(value * val) / val) // Округляем до двух знаков после запятой
	}

	return new Intl.NumberFormat('ru-RU').format(value) // Возвращаем число без изменений, если оно целое
}

export function useValueCurrency(
	hasCountryCurrency?: boolean,
	//@ts-ignore
	fixedNum?: number = 2
) {
	const { data } = useGetExchange()
	const { data: dataCountries } = useGetCountries()
	const { currentCurrency } = useContext(CommonContext)
	const { label = 'RUB' } = currentCurrency

	const getCurrencyCounty = (value: number | string) => {
		if (!data || !label || label === 'RUB') {
			const numValue = Number(
				value.toString().replaceAll(',', '.').replaceAll(' ', '')
			)

			if (isNaN(numValue)) return value

			return hasCountryCurrency
				? `${roundIfNotInteger(numValue, fixedNum)} ${label}`
				: roundIfNotInteger(numValue, fixedNum)
		}

		//Получаем значение курса выбранной валюты к рублю
		const valueCurrency = data[label]

		const numValue = Number(
			value.toString().replaceAll(',', '.').replaceAll(' ', '')
		)

		if (isNaN(numValue)) return value

		if (label === 'USD') return roundIfNotInteger(numValue, 2)

		const result = numValue / valueCurrency

		const resultValue = roundIfNotInteger(result, fixedNum)

		if (isNaN(numValue)) return value

		if (!hasCountryCurrency) return resultValue

		return `${resultValue} ${label}`
	}

	const getCountCurrency = (currency: 'USD' | 'EUR') => {
		if (!data) return

		if (!label || label === 'RUB') return data[currency].toFixed(2)

		const res = data[currency] / data[label]
		const result = Math.floor(res * 100) / 100

		return result
	}

	const country =
		dataCountries?.find(item => item.id === currentCurrency?.value)?.name ||
		'Россия'

	return {
		getCurrencyCounty,
		getCountCurrency,
		currency: label,
		country,
		currenciesExchange: data
	}
}
