import { useLocation } from 'react-router'

export const useQueryParams = () => {
	const location = useLocation()

	const { search } = location
	const clearSearch = search.replace('?', '')
	const helperArray = clearSearch.split('&')

	const data = helperArray.reduce((acc, item) => {
		const [name, value] = item.split('=')
		return {
			...acc,
			[name]: value?.includes(',')
				? value.split(',')
				: decodeURI(value).replace('$', '#')
		}
	}, {})
	return data as Record<string,any>
}
